import './jquery-loader';

import dayJs from 'dayjs';
window.dayjs = dayJs;

import 'dayjs/locale/ro';
window.dayjs.locale('ro');

import customParseFormat from 'dayjs/plugin/customParseFormat';
window.dayjs.extend(customParseFormat);

import relativeTime from 'dayjs/plugin/relativeTime';
window.dayjs.extend(relativeTime);

import localizedFormat from 'dayjs/plugin/localizedFormat';
window.dayjs.extend(localizedFormat);

import localeData from 'dayjs/plugin/localeData';
window.dayjs.extend(localeData);

import weekday from 'dayjs/plugin/weekday';
window.dayjs.extend(weekday);

import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
window.dayjs.extend(isSameOrBefore);

import businessAdd from './business-add';
window.dayjs.extend(businessAdd);

import flatpickr from 'flatpickr';
window.flatpickr = flatpickr;

import Cropper from 'cropperjs';
window.Cropper = Cropper;

let token = document.head.querySelector('meta[name=csrf-token]');
window.$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': token.content
    }
});
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods.js';
import './validations';

import './form-helper';
import './daterangepicker-dayjs';
import './multiline-ellipsis';
// require('./scrollUp');
import './debounce';

import tippy, { createSingleton } from 'tippy.js';
window.Backend.tippy = tippy;
window.Backend.tippy.hideOnPopperBlur = () => {
    return {
        name: 'hideOnPopperBlur',
        defaultValue: true,
        fn(instance) {
            return {
                onCreate() {
                    instance.popper.addEventListener('focusout', (event) => {
                        if (instance.props.hideOnPopperBlur && event.relatedTarget && !instance.popper.contains(event.relatedTarget)) {
                            instance.hide();
                        }
                    });
                },
            };
        },
    }
};
window.Backend.tippy.hidePopperOnEscape = () => {
    return {
        name: 'hidePopperOnEscape',
        defaultValue: true,
        // fn(instance) {
        //     return {
        //         onCreate() {
        //             instance.popper.addEventListener('keydown', (event) => {
        //                 if (instance.props.hidePopperOnEscape && event.target && instance.popper.contains(event.target) && event.keyCode === 27) {
        //                     instance.hide();
        //                     event.preventDefault();
        //                 }
        //             });
        //         },
        //     };
        // },
        fn({hide}) {
            function onKeyDown(event) {
                if (event.keyCode === 27) {
                    hide();
                }
            }

            return {
                onShow() {
                    document.addEventListener('keydown', onKeyDown);
                },
                onHide() {
                    document.removeEventListener('keydown', onKeyDown);
                },
            };
        },
    }
};
window.Backend.tippySingleton = createSingleton;

import Alpine from 'alpinejs';
import Focus from '@alpinejs/focus';
import Collapse from '@alpinejs/collapse';
import Persist from '@alpinejs/persist';
// import money from 'alpinejs-money';

Alpine.plugin(Focus);
Alpine.plugin(Collapse);
Alpine.plugin(Persist);
// Alpine.plugin(money);

Alpine.store('tippy', {
    // isOpen: Alpine.$persist(false).as('tippy.isOpen'),
    isOpen: false
});

Alpine.directive('currency', (el, { expression, modifiers }, { evaluateLater, effect }) => {
    const [modLocale, modCurrency, modRounding] = modifiers || [false, false, false];
    console.log(modRounding);
    const isDecimal = modifiers.includes('decimal');
    const isShopify = modifiers.includes('shopify');
    const isGlobal = modifiers.includes('global');

    const {
        dataset: {
            locale: dataLocale,
            currency: dataCurrency,
            rounding: dataRounding,
        },
    } = el;

    let formatLang = '';
    let formatCurrency = '';
    let formatRounding = 2;

    if (isGlobal) {
        const { locale: globalLocale, currency: globalCurrency, rounding: globalRounding } = window?.xMoney || {};

        formatLang = globalLocale;
        formatCurrency = globalCurrency;
        formatRounding = globalRounding;
    }

    if (isShopify) {
        const {
            locale: shopifyLocale,
            currency: { active: shopifyCurrency },
        } = window?.Shopify || {};

        formatLang = shopifyLocale;
        formatCurrency = shopifyCurrency;
    }

    if (!isShopify && !isGlobal) {
        formatLang = modLocale || dataLocale;
        formatCurrency = modCurrency || dataCurrency;
        formatRounding = modRounding || dataRounding;
    }

    const getValue = evaluateLater(expression);

    effect(() => {
        getValue((moneyValue) => {
            if (!moneyValue || !formatLang || !formatCurrency) {
                return;
            }

            const formattedMoney = isDecimal ? moneyValue : moneyValue / 100;
            const formattedPrice = new Intl.NumberFormat(formatLang, {
                style: 'currency',
                currency: formatCurrency,
                minimumFractionDigits: formatRounding,
                maximumFractionDigits: formatRounding,
            }).format(formattedMoney);

            el.textContent = formattedPrice;
        });
    });
});

// Alpine.directive('money', (el, { value, modifiers }) => {
//     const formatter = new Intl.NumberFormat(modifiers.locale, {
//         style: 'currency',
//         currency: modifiers.currency,
//         minimumFractionDigits: modifiers.rounding,
//         maximumFractionDigits: modifiers.rounding,
//     });

//     el.textContent = formatter.format(value);
// });

// Alpine.store('sidebar', {
//     isOpen: Alpine.$persist(false).as('isOpen'),

//     collapsedGroups: Alpine.$persist([]).as('collapsedGroups'),

//     groupIsCollapsed: function (group) {
//         return this.collapsedGroups.includes(group)
//     },

//     collapseGroup: function (group) {
//         if (this.collapsedGroups.includes(group)) {
//             return
//         }

//         this.collapsedGroups = this.collapsedGroups.concat(group)
//     },

//     toggleCollapsedGroup: function (group) {
//         this.collapsedGroups = this.collapsedGroups.includes(group)
//             ? this.collapsedGroups.filter(
//                   (collapsedGroup) => collapsedGroup !== group,
//               )
//             : this.collapsedGroups.concat(group)
//     },

//     close: function () {
//         this.isOpen = false
//     },

//     open: function () {
//         this.isOpen = true
//     },
// });

window.Alpine = Alpine;

Alpine.start();

import select2 from 'select2/dist/js/select2.min.js';
import 'select2/dist/js/i18n/ro.js';
window.$.fn.select2.defaults.set('language', 'ro');
window.$.fn.select2.defaults.set('selectionCssClass', ':all:');

import MicroModal from './micromodal';

import autonumeric from 'autonumeric';
window.AutoNumeric = autonumeric;

import Toastr from 'toastr2';
window.toastr = new Toastr;

import PerfectScrollbar from 'perfect-scrollbar';
window.PerfectScrollbar = PerfectScrollbar;

import EasyMDE from 'easymde';
window.EasyMDE = EasyMDE;
import Autocomplete from '@trevoreyre/autocomplete-js';
window.Autocomplete = Autocomplete;

import GLightbox from 'glightbox';
window.GLightbox = GLightbox;
