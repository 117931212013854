(function ($) {
    $.validator.addMethod('minRo', function( value, element, param ) {
        return this.optional( element ) || Math.trunc( value * 100 ) >= param;
    });

	$.validator.addMethod('maxRo', function( value, element, param ) {
        return this.optional( element ) || Math.trunc( value * 100 ) <= param;
    });

    // jQuery Validator method for required not blank.
    $.validator.addMethod('requiredNotBlank', function(value, element) {
        return $.validator.methods.required.call(this, $.trim(value), element);
    }, $.validator.messages.required);

    // Password must contain mixed case characters
    $.validator.addMethod('passwordMixedCase', function(value) {
        return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])/.test(value);
    });

    // Password must contain symbols
    $.validator.addMethod('passwordSymbols', function(value) {
        return /^(?=.*[\W])/.test(value);
    });

    // Password must contain numbers
    $.validator.addMethod('passwordNumbers', function(value) {
        return /^(?=.*\d)/.test(value);
    });

    // Positive integer check
    $.validator.addMethod('positiveInteger', function (value) {
        return parseInt(value) > 0;
    }, 'Trebuie introdus un numar intreg mai mare decat 0!');

    // Positive float check
    $.validator.addMethod('positiveFloat', function (value) {
        return parseFloat(value) > 0;
    }, 'Trebuie introdus un numar mai mare decat 0!');

    // Phone number
    $.validator.addMethod('phonenumber', function (value) {
        return /^0\d{9}$/.test(value);
    });

    $.validator.addMethod('phoneRO', function(phone_number, element) {
        phone_number = phone_number.replace(/\(|\)|\s+|-/g, '');
        return this.optional(element) || phone_number.length > 9 &&
            phone_number.match(/^(?:(?:(?:00\s?|\+)40\s?|0)(?:7\d{2}\s?\d{3}\s?\d{3}|(21|31)\d{1}\s?\d{3}\s?\d{3}|((2|3)[3-7]\d{1})\s?\d{3}\s?\d{3}|(8|9)0\d{1}\s?\d{3}\s?\d{3}))$/);
    }, 'Va rugam sa introduceti un numar de telefon valid pentru Romania');

    // Positive number check with 2 decimals
    $.validator.addMethod('positive2decimals', function (value) {
        return /^\+?[0-9]*(\.[0-9]{1,2})?$/.test(value);
    });

    //CIF Validation
    $.validator.addMethod('isCIF', function (value) {
        if ( /^[0-9]{6,10}$/.test(value) ) {
            return true;
        } else if (value.substring(0, 2).toUpperCase() == 'RO' ) {
            return /^[0-9]{6,10}$/.test(value.slice(2));
        }
    });

    // Email validation
    $.validator.methods.email = function( value, element ) {
        // See http://rosskendall.com/blog/web/javascript-function-to-check-an-email-address-conforms-to-rfc822
        //return this.optional( element ) || /^(([^<>()\[\]\\.,;:\s@']+(\.[^<>()\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test( value );
        return this.optional( element ) || /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test( value );
    }
    // https://gist.github.com/dperini/729294
    $.validator.methods.url = function( value, element ) {
        return this.optional( element ) || /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/i.text( value );
    }

    //$.validator.methods.email = function( value, element ) {
    //    return this.optional( element ) || /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}/.test( value );
    //}

    $.validator.addMethod('zeroOrGreater', function(value, element) {

        return this.optional(element) || (parseFloat(value) >= 0);
    }, 'Value must be greater then 0.');

    $.validator.addMethod('notEqualTo', function (value, element, param) {
        let target = $(param);

        if (this.settings.onfocusout && target.not('.validate-notEqualTo-blur').length) {
            target.addClass('validate-notEqualTo-blur').on('blur.validate-notEqualTo', () => {
                $(element).valid();
            });
        }

        return this.optional(element) || value != target.val();
    }, 'Please enter a different value.');

    // $.validator.addMethod('greaterOrEqualThan', function(value, element, param) {
    //     let target = $(param);

    //     if (this.settings.onfocusout && target.not('.validate-greaterOrEqualThan-blur').length) {
    //         target.addClass('validate-greaterOrEqualThan-blur').on('blur.validate-greaterOrEqualThan', () => {
    //             $(element).valid();
    //         });
    //     }

    //     return parseFloat(value) >= parseFloat(target.val());
    // }, 'Please enter a value greater or equal.');

    $.validator.setDefaults({
        validClass: 'is-valid',
        errorElement: 'div',
        errorClass: 'invalid-feedback',
        focusInvalid: true,
        onkeyup: false,
        ignore: ':hidden .ignore',
        errorPlacement: function (error, element) {
            error.appendTo(element.parents('.form-group'));
        },
        highlight: function (element) {
            $(element).addClass('is-invalid');
            $(element).parents('.form-group').addClass('has-error');
        },
        unhighlight: function (element) {
            $(element).removeClass('is-invalid');
            $(element).parents('.form-group').removeClass('has-error');
        },
        success: function (label) {
            label.removeClass('is-invalid');
            label.parents('.form-group').removeClass('has-error');
            label.closest('.form-group').find('.invalid-feedback').remove();
        }
    });
})(jQuery);
