(function ($) {
    $.fn.serialize = function (options) {
        return $.param(this.serializeArray(options));
    };

    $.fn.serializeArray = function (options) {
        var o = $.extend({ checkboxesAsBools: false }, options || {});
        var rselectTextarea = /select|textarea/i;
        var rinput = /text|hidden|password|search|number|url|email/i;

        return this.map(function () {
            return this.elements ? $.makeArray(this.elements) : this;
        })
        .filter(function () {
            return this.name
                && (this.checked || (o.checkboxesAsBools && this.type === 'checkbox')
                || rselectTextarea.test(this.nodeName)
                || rinput.test(this.type));
        })
        .map(function (i, elem) {
            let val = $(this).val();
            return val == null
                ? null
                : Array.isArray(val)
                    ? $.map(val, function (val, i) {
                        return { name: elem.name, value: val };
                    })
                    : {
                        name: elem.name,
                        value: (o.checkboxesAsBools && this.type === 'checkbox')  //moar ternaries!
                            ? (this.checked ? 'true' : 'false')
                            : val
                    };
        })
        .get();
    };
})(jQuery);
