export default (function (o, c, dayjsFactory) {
  let proto = c.prototype;
  
  function determineSign(x) {
    x = +x;
    return x > 0 ? 1 : -1;
  }
  
  proto.businessAdd = function(number) {
    if (number === 0 || isNaN(number)) { return this; }

    var sign = determineSign(number);
    
    var day = this.day();
    var absIncrement = Math.abs(number);
    var days = 0;

    if (day === 0 && sign === -1) {
      days = 1;
    } else if (day === 6 && sign === 1) {
      days = 1;
    }

    // Add padding for weekends.
    var paddedAbsIncrement = absIncrement;
    if (day !== 0 && day !== 6 && sign > 0) {
      paddedAbsIncrement += day;
    } else if (day !== 0 && day !== 6 && sign < 0) {
      paddedAbsIncrement += 6 - day;
    }
    
    var weekendsInbetween =
      Math.max(Math.floor(paddedAbsIncrement / 5) - 1, 0) +
      (paddedAbsIncrement > 5 && paddedAbsIncrement % 5 > 0 ? 1 : 0);

    // Add the increment and number of weekends.
    days += absIncrement + weekendsInbetween * 2;

    let d = this.add(sign * days, 'days');
    
    return d;
  }
    
  
  proto.businessSubtract = function(number){
    return this.businessAdd(-number);
  };
});